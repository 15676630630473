export const LANDING = "VIEW_LANDING";
export const CATEGORIES = "VIEW_CATEGORIES";
export const SUBCATEGORIES = "VIEW_SUBCATEGORIES";
export const CONFIRMATION = "VIEW_CONFIRMATION";
export const RESOLUTION = "VIEW_RESOLUTION";
export const WSCFORM = "VIEW_WSCFORM";
export const COMPLETE = "VIEW_COMPLETE";
export const TILES = "VIEW_TILES";

const CONSTANTS = {
  LANDING,
  SUBCATEGORIES,
  CATEGORIES,
  CONFIRMATION,
  RESOLUTION,
  WSCFORM,
  COMPLETE,
  TILES,
};

export default CONSTANTS;

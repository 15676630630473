import Layout from "layouts/default";

import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
  matchPath,
} from "react-router-dom";

import { fetchData } from "store/Api";
import { initData, updateState } from "store/slices/applicationSlice";
import { useDispatch, useSelector } from "react-redux";

import { ReactGA, GA_EVENTS } from "util/analytics";

import Loading from "components/Loading";
import Categories from "components/Categories";
import { useEffect, useState } from "react";
import CONSTANTS from "store/contstants";

const getView = (params, sub) => {
  if (sub) {
    if (sub.action === "tiles") return CONSTANTS.TILES
    if (sub.action === "form") return CONSTANTS.RESOLUTION;
    if (sub.action === "wsc") return CONSTANTS.WSCFORM;
    return CONSTANTS.CONFIRMATION;
  } else if (params.cat) {
    return CONSTANTS.SUBCATEGORIES;
  }
  return CONSTANTS.CATEGORIES;
};

const RouterAdapter = () => {
  const { roles, subcategories } = useSelector((state) => state.application);

  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname === "/") {
      history.push("/" + roles[0].slug);
    } else {
      const { params } = matchPath(pathname, {
        path: "/:role/:type?/:cat?/:sub?",
        exact: true,
        strict: false,
      });

      const roleIndex = roles.findIndex((role) => role.slug === params.role);
      const catIndex = roles[roleIndex].categories.findIndex(
        (cat) => cat.slug === params.cat
      );
      
      if (params.type === "existing") {
        dispatch(
          updateState({
            view: CONSTANTS.RESOLUTION,
            selection: {
              role: roles[roleIndex].id,
              roleIndex,
              category: null,
              categoryIndex: -1,
              subcategory: null,
            },
          })
          );
        } else {
        const roleId = roles[roleIndex]?.id
        const catId = roles[roleIndex]?.categories[catIndex]?.id
        const sub = subcategories.find((item) => item.role === roleId && item.category === catId && item.id === params.sub);
        dispatch(
          updateState({
            view: getView(params, sub),
            selection: {
              role: roles[roleIndex].id,
              roleIndex,
              category: roles[roleIndex].categories[catIndex]?.id || undefined,
              categoryIndex: catIndex,
              subcategory: sub || null,
            },
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  return null;
};

function App() {
  const ready = useSelector((state) => state.application.ready);
  const [isVisible, setIsVisible] = useState(true)

  const onScroll = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    fetchData().then(({ roles, faqs, subcategories }) => {
      dispatch(initData({ roles, faqs, subcategories }));
    });
    ReactGA.event(GA_EVENTS.pageview, { page_title: "Homepage", page_id: "/" });
    // eslint-disable-next-line

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, []);

  const dispatch = useDispatch();

  if (!ready) {
    return <Loading />;
  }

  return (
    <div className="App">
      {isVisible && <div id='scroll-indicator'></div>}
      <Router>
        <RouterAdapter />
        <Layout>
          <Categories />
        </Layout>
      </Router>
    </div>
  );
}

export default App;
